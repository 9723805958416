<template>
  <div v-if="ready" class="text world-editor-select">
    <div class="d-flex justify-content-end">
      <ion-button class="edit-btn" @click="isOpen = true">{{ buttonText }}</ion-button>
    </div>
    <div v-if="worldCharacters?.length">
      <Grid :lg="3" :md="3" :sm="2" :scrollSm="false">
        <CharacterCard
          v-for="(character, index) of worldCharacters"
          :key="index"
          class="grid-item"
          :character="character"
          hidePopover
        />
      </Grid>
    </div>
    <div v-else class="no-data">No characters in this world yet</div>
    <SelectedCharactersModal
      :all-characters="allCharacters"
      :world-characters="worldCharacters"
      :is-open="isOpen"
      @dismiss-modal="isOpen = false"
      @save="save"
    />
  </div>
</template>

<script lang="ts" setup>
import { CharacterInWorld } from '@/shared/types/static-types';
import SelectedCharactersModal from '../modals/SelectedCharactersModal.vue';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { getUserCharacters } from '@/shared/actions/characters';
import { authStore } from '@/shared/pinia-store/auth';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';

const { user } = authStore();

const { changeInfoKey, storyworld } = storyWorldCreatorStore();

const props = defineProps({
  usedKey: {
    type: String,
    default: '',
  },
  value: {
    type: Array,
    default: () => [],
  },
});

const isOpen = ref(false);

const ready = ref(true);
const usedKey = toRef(props, 'usedKey');

const buttonText = computed(() => (worldCharacters.value?.length ? 'Edit/Reorder' : 'Add characters'));

const save = (mutatedWorldCharacters: CharacterInWorld[]) => {
  changeInfoKey(usedKey.value, mutatedWorldCharacters);
};

const allCharacters = ref([] as any[]);

const fetchCharacters = async () => {
  try {
    const response = await getUserCharacters(user.value.id, null, { incl_u_m: true });
    allCharacters.value = response;
  } catch (_e) {}
};

onMounted(async () => {
  await fetchCharacters();
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const worldCharacters = computed(() => storyworld.value?.character_worlds as CharacterInWorld[]);
</script>

<style lang="sass" scoped>
.edit-btn
  --border-radius: 8px
  text-transform: unset
</style>
